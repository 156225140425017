$(document).ready(() => {
  // One Page Nav
  // var top_offset = $('.header-area').height() - 100;
  // $('.main-menu nav ul').onePageNav({
  //   currentClass: 'active',
  //   scrollOffset: top_offset,
  // });

  // RESPONSIVE MENU
  // $('.responsive').on('click', function (e) {
  //   console.log('responsive');
  //   $('#mobile-menu').slideToggle();
  // });


  // menu toggle
  $(".main-menu li a").on('click', function () {
    if ($(window).width() < 1200) {
      $("#mobile-menu").slideUp();
    }
  });

  // smoth scroll
  $(function () {
    $('a.smoth-scroll').on('click', function (event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top - 100
      }, 1000);
      event.preventDefault();
    });
  });

  // mainSlider
  function mainSlider() {
    var BasicSlider = $('.slider-active');
    BasicSlider.on('init', function (e, slick) {
      var $firstAnimatingElements = $('.single-slider:first-child').find('[data-animation]');
      doAnimations($firstAnimatingElements);
    });
    BasicSlider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
      var $animatingElements = $('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
      doAnimations($animatingElements);
    });
    BasicSlider.slick({
      autoplay: true,
      autoplaySpeed: 10000,
      dots: false,
      fade: true,
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev"><i class="icon dripicons-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="icon dripicons-chevron-right"></i></button>',
      responsive: [
        { breakpoint: 1200, settings: { dots: false, arrows: false } }
      ]
    });

    function doAnimations(elements) {
      var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
      elements.each(function () {
        var $this = $(this);
        var $animationDelay = $this.data('delay');
        var $animationType = 'animated ' + $this.data('animation');
        $this.css({
          'animation-delay': $animationDelay,
          '-webkit-animation-delay': $animationDelay
        });
        $this.addClass($animationType).one(animationEndEvents, function () {
          $this.removeClass($animationType);
        });
      });
    }
  }
  mainSlider();


  // blog
  $('.blog-active').slick({
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></i></button>',
  });

  // counterUp

  $('.count').counterUp({
    delay: 100,
    time: 1000
  });

  /* magnificPopup img view */
  $('.popup-image').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true
    }
  });

  /* magnificPopup video view */
  $('.popup-video').magnificPopup({
    type: 'iframe'
  });

  // paroller
  if ($('.paroller').length) {
    $('.paroller').paroller();
  }

  //* Parallaxmouse js
  function parallaxMouse() {
    if ($('#parallax').length) {
      var scene = document.getElementById('parallax');
      var parallax = new Parallax(scene);
    };
  };
  parallaxMouse();

  // service active
  $('.s-single-services').on('mouseenter', function () {
    $(this).addClass('active').parent().siblings().find('.s-single-services').removeClass('active');
  })




  // isotop
  $('.grid').imagesLoaded(function () {
    // init Isotope
    var $grid = $('.grid').isotope({
      itemSelector: '.grid-item',
      percentPosition: true,
      masonry: {
        // use outer width of grid-sizer for columnWidth
        columnWidth: 1
      }
    });

    // filter items on button click
    $('.button-group').on('click', 'button', function () {
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({ filter: filterValue });
    });

  });
  // isotop
  $(".element").each(function () {
    var a = $(this);
    a.typed({
      strings: a.attr("data-elements").split(","),
      typeSpeed: 100,
      backDelay: 3e3
    })
  }),
    //for menu active class
    $('.button-group > button').on('click', function (event) {
      $(this).siblings('.active').removeClass('active');
      $(this).addClass('active');
      event.preventDefault();
    });

  // WOW active
  new WOW().init();

});

